jQuery(function($) {
  var $projectsContainer = $('#projects');
  var projectsList = [];

  function getProjects() {
    $.ajax({
      url: 'https://admin.ose.com.hk/api/projects',
      type: 'GET',
      dataType: 'JSON'
    }).then(function(response) {
      if (response && response.data) {
        projectsList = response.data.projects;
        loadProjects(response.data.projects);
      }
    }).fail(function(response, message, jqXhr) {
      console.error(response);
    });
  }

  function loadProjectsCarousel(key, $modal) {
    if (Object.hasOwnProperty.call(projectsList, key)) {
      var project = projectsList[key];
      var $projectCarousel = $modal.find('#photoCarousel:first');
      var $projectCarouselInner = $projectCarousel.find('.carousel-inner');
      $projectCarouselInner.find('.carousel-item').remove();

      if (project.attachments && project.attachments.length > 0) {
        project.attachments.forEach(function(element, idx) {
          var imageUrl = element.file;
          var $item = $projectCarousel.find('.project-item-template:first').clone();
          $item.find('img:first').attr('src', imageUrl);
          $item.removeClass('project-item-template');
          $item.addClass('carousel-item');
          if (idx == 0) {
            $item.addClass('active show');
          }
          $item.find('.photo-stripe .current-photo').text(idx + 1);
          $item.find('.photo-stripe .total-photos').text(project.attachments.length);
          $projectCarouselInner.append($item);
        });
      }
    }
  }

  function loadProjects(projects) {
    var $projectList = $projectsContainer.find('.projects-list:first');
    for (var key in projects) {
      if (Object.hasOwnProperty.call(projects, key)) {
        var project = projects[key];
        if (project.attachments) {
          var $projectClone  = $projectList.find('.project-template:first').clone();
          var $projectTitle = $projectClone.find('.project-title:first');
          // var folderName = project.title;
          var imageUrl = project.attachments[0].file;
          var $item = $projectClone.find('.project-item-template:first');
          $item.find('.project-image:first').attr('src', imageUrl);
          $item.attr('data-target-project', key);
          $item.removeClass('project-item-template');

          $projectTitle.text(project.title);
          $projectClone.removeClass('project-template');
          $projectList.append($projectClone);
        }
      }
    }
  }

  getProjects();

  $('#photo-modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget),
      name = button.data('target-project'),
      $modal = $(this);
    loadProjectsCarousel(name, $modal);
  });

  $(document.body).on('click', 'a.back-to-top', function(evt) {
    evt.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  var amountScrolled = 300;
  $(window).scroll(function() {
    var $mainMenu = $('#main-menu');
    var $toTop = $('a.back-to-top');
    if ( $(window).scrollTop() > amountScrolled) {
      // $toTop.css("display", "flex");
      $mainMenu.addClass('active');
    } else {
      // $toTop.css("display", "none");
      $mainMenu.removeClass('active');
    }
  });
});
